<template>
  <div id="sale-invoice-list">
    <!-- user total card -->
    <v-row class="mb-5">
      <!--<v-col
        v-for="total in saleInvoiceTotalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveItemTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveItemTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveItemTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveItemTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col> -->

        <!-- plan filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->

        <!-- status filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <table-search
              v-model="options.q"
              dense
              outlined
              hide-details
              full-width
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="saleInvoices"
        :options="options"
        :search.sync="search"
        :meta.sync="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- number -->
        <template #[`item.number`]="{item}">
          <router-link
            :to="{ name : 'sale-invoice-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.number }}
          </router-link>
        </template>

        <!-- customer -->
        <template #[`item.customerName`]="{item}">
          <router-link
            :to="{ name : 'customer-view', params : { id : item.customerId } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <div class="d-flex flex-column">
              <span class="d-block text--primary  font-weight-semibold text-truncate">{{ item.customerName }}</span>
              <span class="text-xs text--secondary font-weight-light">{{ item.email }}</span>
            </div>
          </router-link>
        </template>

        <!-- totalAmountIncludingTax with currencyCode -->
        <template #[`item.totalAmountIncludingTax`]="{item}">
          <span v-if="item.currencyCode">{{ `${item.currencyCode} ` }}</span>
          <span>{{ item.totalAmountIncludingTax }}</span>
        </template>

        <!-- invoice date -->
        <template #[`item.invoiceDate`]="{item}">
          <span>{{ item.invoiceDate | toLocalDate }}</span>
        </template>

        <!-- due date -->
        <template #[`item.dueDate`]="{item}">
          <span>{{ item.dueDate | toLocalDate }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <div class="d-flex">
            <v-chip
              small
              :color="resolveStatusColor(item.status)"
              class="v-chip-light-bg font-weight-semibold text-uppercase me-1"
              :class="`${resolveStatusColor(item.status)}--text`"
            >
              {{ item.status }}
            </v-chip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'sale-invoice-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Sale Invoice</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiExportVariant } from '@mdi/js'
import useSaleInvoiceList from './useSaleInvoiceList'

export default {
  setup() {
    const {
      saleInvoiceListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalSaleInvoiceListTable,
      loading,

      resolveItemTotalIcon,

      meta,
      saleInvoices,

      options,
      filters,
      search,

      resolveStatusColor,

      init,
      destroy,
    } = useSaleInvoiceList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'User', value: 'user' },
    ]

    return {
      saleInvoiceListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalSaleInvoiceListTable,
      roleOptions,

      loading,

      resolveItemTotalIcon,

      meta,
      saleInvoices,

      options,
      filters,
      search,

      icons: {
        mdiEyeOutline,
        mdiExportVariant,
      },

      resolveStatusColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>

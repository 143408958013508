import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"sale-invoice-list"}},[_c(VRow,{staticClass:"mb-5"}),_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VRow,{staticClass:"px-2 ma-0"}),_c('vuex-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.saleInvoices,"options":_vm.options,"search":_vm.search,"meta":_vm.meta,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      }},on:{"update:search":function($event){_vm.search=$event},"update:meta":function($event){_vm.meta=$event},"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'sale-invoice-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.customerName",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'customer-view', params : { id : item.customerId } }}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.customerName))]),_c('span',{staticClass:"text-xs text--secondary font-weight-light"},[_vm._v(_vm._s(item.email))])])])]}},{key:"item.totalAmountIncludingTax",fn:function(ref){
      var item = ref.item;
return [(item.currencyCode)?_c('span',[_vm._v(_vm._s(((item.currencyCode) + " ")))]):_vm._e(),_c('span',[_vm._v(_vm._s(item.totalAmountIncludingTax))])]}},{key:"item.invoiceDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toLocalDate")(item.invoiceDate)))])]}},{key:"item.dueDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("toLocalDate")(item.dueDate)))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-uppercase me-1",class:((_vm.resolveStatusColor(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusColor(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'sale-invoice-view', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Sale Invoice")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
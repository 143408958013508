// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, readonly, ref, toRaw, watch } from '@vue/composition-api'

export default function useSaleInvoiceList() {
  const vm = getCurrentInstance().proxy

  const saleInvoiceListTable = ref([])

  const tableColumns = [
    { text: 'NUMBER', value: 'number' },
    { text: 'CUSTOMER', value: 'customerName' },

    // { text: 'SALESPERSON', value: 'salesperson' },
    { text: 'TOTAL', value: 'totalAmountIncludingTax' },
    { text: 'STATUS', value: 'status' },
    { text: 'INVOICE DATE', value: 'invoiceDate' },

    // { text: 'DUE DATE', value: 'dueDate' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const saleInvoices = computed({
    get: () => vm.$store.getters['saleInvoices/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['saleInvoices/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['saleInvoices/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['saleInvoices/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['saleInvoices/options'],
    set: val => vm.$store.dispatch('saleInvoices/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['saleInvoices/search'],
    set: val => vm.$store.dispatch('saleInvoices/setSearch', val),
  })
  const totalSaleInvoiceListTable = computed({
    get: () => vm.$store.getters['saleInvoices/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('saleInvoices/fetchItems')
  const setOptions = val => vm.$store.dispatch('saleInvoices/setOptions', val)
  const setFilters = val => vm.$store.dispatch('saleInvoices/setFilters', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  const resolveStatusColor = status => {
    switch (status.toLowerCase()) {
      case 'paid':
        return 'primary'

      case 'open':
        return 'info'

      case 'canceled':
        return 'warning'

      default:
        return 'secondary'
    }
  }

  return {
    saleInvoiceListTable,
    tableColumns,

    totalSaleInvoiceListTable,
    loading,

    // options,
    // saleInvoiceTotalLocal,
    fetchItems,

    meta,
    saleInvoices,

    options,
    filters,
    search,

    setOptions,
    setFilters,

    resolveStatusColor,

    init,
    destroy,
  }
}
